import React, { useState } from "react"
import { Link } from "gatsby"
import CardImage from "../../images/Cards/Card3.jpg"
import Img from "gatsby-image"
import Modal from "react-modal"

const GallerytCard2 = props => {
  // console.log(props)
  const customStyles = {
    content: {
      border: "1px",
      top: "1px",
      bottom: "1%",
      left: "1%",
      overflow: "hidden",
      position: "fixed",
      width: "100%",
      maxHeight: "99%",
    },
  }

  const [isOpen, setIsOpen] = useState(false)

  function toggleModal() {
    if (window.innerWidth > 800) {
      setIsOpen(!isOpen)
    }
  }

  return (
    <>
      <div
        className="card  mt-10 "
        onClick={e => {
          e.preventDefault()
          toggleModal()
        }}
      >
        <div className="invisible">
          <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            style={customStyles}
            ariaHideApp={false}
            shouldFocusAfterRender={false}
          >
            <button
              onClick={e => {
                e.preventDefault()
                toggleModal()
              }}
            >
              CLOSE
            </button>
            <Img
              fluid={props.image.gallery_image.fluid}
              style={{ maxHeight: "100%", maxWidth: "100%" }}
              imgStyle={{ objectFit: "contain" }}
            />
          </Modal>
        </div>

        <Img
          fluid={props.image.gallery_image.fluid}

          //style={{ height: "100%", width: "100%" }}
        />

        <div className="text-center text-lg  mt-2">
          <p className="font-bold">{props.image.image_name.text}</p>
        </div>

        {/* <div className="grid grid-cols-3 gap-4 m-1">
          <span className=" col-span-2">{props.image.image_name.text}</span>
          <span className="inset-y-0 right-0 text-right">
            {props.image.medium}
          </span>
        </div> */}
      </div>
    </>
  )
}

export default GallerytCard2
